export default class SliderMarquee {
	constructor(widget) {
		let tickerSpeed = 1.5;
		let flickity = null;
		let isPaused = false;
	  
		const slideshowEl = widget.querySelector('.js-flickity');

		const update = () => {
			if (isPaused) return;

			if (flickity.slides) {
				flickity.x = (flickity.x - tickerSpeed) % flickity.slideableWidth;
				flickity.selectedIndex = flickity.dragEndRestingSelect();
				flickity.updateSelectedSlide();
				flickity.settle(flickity.x);
			}

			window.requestAnimationFrame(update);
		};

		// * Function to determine if element is in view
		function isScrolledIntoView(el) {
			let rect = el.getBoundingClientRect();
			let elemTop = rect.top;
			let elemBottom = rect.bottom;
			
			// * Partially visible elements return true:
			let isVisible = elemTop < window.innerHeight && elemBottom >= 0;

			return isVisible;
		}

		// * delays resize trigger
		function debounce(func, time){
			var time = time || 200; // 200 by default if no param
			var timer;
			
			return function(event){
				if(timer) clearTimeout(timer);
				timer = setTimeout(func, time, event);
			};
		}
		
		function resizeContent() {
			if(!isScrolledIntoView(widget)) return

			slideshowEl.dispatchEvent(new Event('play'))
		}

		const pause = () => {
			isPaused = true;
		};
		
		const play = () => {
			if (isPaused) {
				isPaused = false;
				window.requestAnimationFrame(update);
			}
		};

		flickity = new Flickity(slideshowEl, {
			autoPlay: false,
			prevNextButtons: false,
			pageDots: false,
			draggable: false,
			wrapAround: true,
			selectedAttraction: 0.015,
			friction: 0.25,
		});

		flickity.x = 0;

		slideshowEl.addEventListener('mouseleave', play, false);
		slideshowEl.addEventListener('pause', pause, false);
		slideshowEl.addEventListener('play', play, false);
		
		document.addEventListener('scroll', function() {
			if(!isScrolledIntoView(widget)) {
				slideshowEl.dispatchEvent(new Event('pause'));

				return;
			}

			slideshowEl.dispatchEvent(new Event('play'));
		})

		window.addEventListener('resize', function() {
			slideshowEl.dispatchEvent(new Event('pause'))
		})
		
		window.addEventListener('resize', debounce( resizeContent ));

		update();
	}
}
