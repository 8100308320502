// *-- Sets scroll direction attribute on target --* \\
export default class ScrollDirection {
    constructor(target, offsetDetection) {
        this.target = $(target)
        this.offsetDetection = offsetDetection || 0
        this.lastScrollTop = 0
        this.direction = ''

        window.addEventListener('scroll', () => this.events(), false)
    }
    
    events() {
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        // * minimum scroll of {this.offsetDetection} required
        if(!(Math.abs(this.lastScrollTop - scrollTop) >= this.offsetDetection)) return 

        if(scrollTop > this.lastScrollTop || scrollTop === 0) {
            this.direction = 'down'

            // * to catch navigation clicks onLoad() that may cause issues with accessibilty
            $('html, body').removeClass('navigation--is-active')
        } else {
            this.direction = 'up'
        }

        $(this.target).attr('data-scroll-direction', this.direction)
        
        this.lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
    }
};