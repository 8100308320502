import $ from 'jquery'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { MotionPathPlugin } from 'gsap/MotionPathPlugin'

gsap.registerPlugin(MotionPathPlugin, ScrollTrigger)

class AnimatedMapPath {
    constructor(el, map) {
        this.elements = {
            wrapper: $(el),
            map: $(map),
            svg: $(map).children('svg').eq(0),
            svgPath: $('#dashedLine'),
            firstPanel: $('.panel-layout > .panel-grid'),
            contentPanels: $('.panel-layout .panel-grid:not(:first-of-type)'), // excludes first panel
            panelRow: 'div[class^=c-row]',
            lastRow: $('.panel-layout > .panel-grid:last-of-type'),
        }

        this.scrollHeight;
        this.combinedContentHeight;
        this.animtedPath;
        
        window.addEventListener('load', () => {
            setTimeout(() => {
                this.calculateSvgHeight()
                this.bindAnimation()
            }, 250)
        })

        window.addEventListener('resize', () => this.resizeCheck())
    }

    calculateSvgHeight() {
        let firstPanelHeight = $(this.elements.firstPanel).children(this.elements.panelRow).outerHeight(),
            pageContentHeight = this.getContainerHeights(),
            lastRowMargin = parseInt(window.getComputedStyle($(this.elements.lastRow).children(this.elements.panelRow)[0]).marginBottom),
            contentHeight = pageContentHeight - lastRowMargin,
            svgHeight = this.elements.svgPath[0].getTotalLength(),
            heightDifference = Math.abs(contentHeight - svgHeight);
        
        this.elements.map[0].style.top = `${firstPanelHeight - 100}px`;
        this.elements.map[0].style.height = `${contentHeight}px`;
        
        if(this.elements.svg.hasClass('background_line_1')) {
            this.scrollHeight = svgHeight - (heightDifference / 3) // line 1 - home
        }
        else if (this.elements.svg.hasClass('background_line_2')) {
            this.scrollHeight = svgHeight - (heightDifference * .90) // line 2 - stay
        }
        else if (this.elements.svg.hasClass('background_line_3')) {
            this.elements.map[0].style.top = `${firstPanelHeight - 150}px`;
            this.scrollHeight = svgHeight + (heightDifference * .15) // line 3 - eat            
        }
        else if (this.elements.svg.hasClass('background_line_4')) {
            this.scrollHeight = svgHeight - (heightDifference * .90) // line 4 - stay
        }
        else if (this.elements.svg.hasClass('background_line_5')) {
            this.scrollHeight = svgHeight - (heightDifference * .65) // line 5 - stay
        }
        else {
            this.scrollHeight = contentHeight;
        }
    }

    bindAnimation() {
        this.animtedPath = gsap.to('#locationPin', {
            scrollTrigger: {
                trigger: this.elements.map,
                start: 'top 37%',
                end: `+=${this.scrollHeight}`,
                scrub: 1,
            },
            motionPath: {
                path: '#dashedLine',
                align: '#dashedLine',
                alignOrigin: [0.5, 0.5],
                autoRotate: 90,
            },
            ease: 'none',
            duration: .5,
            immediateRender: true,
        })

        return this.animtedPath
    }

    getContainerHeights() {
        let combinedContentHeight = 0

        this.elements.contentPanels.each(function() {
            combinedContentHeight += $(this).outerHeight(true)
        })

        return combinedContentHeight
    }

    resizeCheck() {
        if(this.animtedPath == null) return

        let progress = this.animtedPath.progress();
        this.animtedPath.kill();

        this.calculateSvgHeight()
        this.bindAnimation().progress(progress)
    }
}

export default AnimatedMapPath
