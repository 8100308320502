export default class RotatingIconSliderSwiper {
    constructor(element) {
        this.elements = {
            base: element,
            iconSlider: '.c-rotating-icon-slider__icon-slider',
            contentSlider: '.c-rotating-icon-slider__icon-slider',
            items: '.c-rotating-icon-slider__items',
            item: '.c-rotating-icon-slider__item',
            img: '.c-rotating-icon-slider__icon-img',
            sliderTargetAttr: 'data-rotating-icon-target-slide',
        }

        // active class for icons/slides
        this.activeClass = 'is-active'

        // icon slider elements
        this.iconSliderItems = $(`${this.elements.iconSlider} ${this.elements.items}`)[0]
        this.iconSliderItem = $(`${this.elements.iconSlider} ${this.elements.item}`)
        
        // number of items set and calculated degrees to position equally on path
        this.numOfItems = this.iconSliderItem.length
        this.rotationDeg = 360 / this.numOfItems

        // used to guard clause to prevent execution when not on functional viewport
        this.mobileViewport = $('#c-mobile-indicator').is(':visible') || null

        //// if (this.mobileViewport) return // prevent loading/running on mobile

        this.initalIconPostion()
        this.bindEventListeners()
    }

    /**
     * Binds event listeners for icons
     * * includes window resize events
     * * icon click events
     */
    bindEventListeners() {
        /**
         * Debounce function to delay function calls until events have stopped firing
         * @param func Supplied function to be called after last event trigger
         * @param timeout Optional: Supplied time in milliseconds to wait after last event is called. Default: 50ms
         * @returns supplied function on debounced set timeout
         */
        function debounce(func, timeout) {
            let timer = timeout || 50;

            return (...args) => {
                clearTimeout(timer);

                timer = setTimeout(() => {func.apply(this, args)}, timeout)
            }
        }

        // assign debounced function call backs for window resize event listeners
        const getViewport = debounce(() => {
            this.mobileViewport = $('#c-mobile-indicator').is(':visible') || null
        })
        const alignIcons = debounce(() => this.alignIcons());

        // resize triggers variable refresh to see if current VP is in mobile view
        window.addEventListener('resize', getViewport, false)
        // resize re-aligns icons to position on containers circular path
        window.addEventListener('resize', alignIcons, false);


        let resizeTimeout;

        $(window)
            // apply resizing class to element during resize event then remove after events finish
            .on('resize', () => {
                clearTimeout(resizeTimeout)

                $(this.elements.base).addClass('resizing')

                resizeTimeout = setTimeout(() => { 
                    $(this.elements.base).removeClass('resizing')
                }, 50)
            })
            //// .on('resize', () => {
            ////     if(this.iconSliderItem[0].currentPosition === 'undefined') return

            ////     this.initalIconPostion()
            //// })

        // rotates icons on click
        // - destructured event to bring through target only
        this.iconSliderItem.on('click', ({target}) => this.rotateIcons(target))
    }

    /**
     * Set the initial position of icons relative to circular path
     * * Calculate transform distance
     * * Calculate rotation and counter-rotation
     * * Set each item's properties: [ currentRotation | calculatedPosition ]
     * * Set inline css transform property
     */
    initalIconPostion() {
        if (this.mobileViewport) return

        let rotation = this.rotationDeg;

        this.iconSliderItem.each((key, element) => {
            let item = element
            
            // increment rotation by calculated amount store in global var
            rotation += this.rotationDeg

            // set initial positioning and rotation data on each item
            item.currentPosition = key
            item.currentRotation = rotation

            // set inline transform style
            item.style.transform = `rotate(-${rotation}deg) translate(${this.iconSliderItems.clientWidth / 2}px) rotate(${rotation}deg)`
        })
    }

    /**
     * On resize, will recalculate translated position on circular path and update inline transform value (maintains currently set rotation values)
     * 
     * @returns new inline style transform with new translated position, preserving current rotation values
     */
    alignIcons() {
        if (this.mobileViewport) return
        
        let items = this.iconSliderItem;

        items.each((key, element) => {
            let item = element,
                rotation = item.currentRotation;
            
                // reset the transform and briefly override transition duration before removing the inline style
                item.style.transitionDuration = `300ms`
                item.style.transform = `rotate(-${rotation}deg) translate(${this.iconSliderItems.clientWidth / 2}px) rotate(${rotation}deg)`
                setTimeout(() => { item.style.removeProperty('transition-duration') }, 300)
        })
    }

    /**
     * Removes active classes from all content slides and icons (targeted via the this.sliderTargetAttr).
     * Then checks all against supplied target value to determine what is to be set as active.
     * 
     * @param targetAttrValue - Supplied target.attr() value to query
     * @param delay - Supplied delay to wait before adding active class to queried active data-attribute elements
     */
    setActiveSlide(targetAttrValue) {
        // loop through all targeted slides (both content and icon)
        $(`[${this.elements.sliderTargetAttr}]`).each((key, element) => {
            // remove class
            $(element).removeClass(this.activeClass)

            // if not selected icon skip to next element
            if ($(element).attr(this.elements.sliderTargetAttr) !== targetAttrValue) return
            
            $(element).addClass(this.activeClass)
        })
    }

    /**
     * Rotates the icons around the container path
     * @param target this.iconSliderItem.on('click') => {target}
     * 
     * * Checks to see if clicked target is already positioned at the top - if not then continues
     * * Calculates target's distance from top of circular path and uses this to determine the new rotational value required
     * * Then sets the target to the new zero'd rotational value and updates its position to 0
     * * Finally looping through all of the siblings and applying the equal increase in rotational value
     */
    rotateIcons(target) {
        if (this.mobileViewport) return

        // get target's parent __item and check to see if it's already positioned at the top
        let item = $(target).parents(this.elements.item)[0]
        if(item.currentPosition === 0) return

        // get item's target slide and sibling elements
        let itemTargetSlide = $(item).attr(this.elements.sliderTargetAttr),
            siblings = $(item).siblings();

        // get target current data
        let currentPosition = item.currentPosition,
            currentRotation = item.currentRotation;

        // calculate distance from Zero'd Position (top of circular path)
        let distanceFromTop = Math.abs(this.numOfItems - currentPosition)

        // calculate newRotation value and set newPosition
        let newRotation = Math.abs(currentRotation + (distanceFromTop * this.rotationDeg));

        // set rotation delay - set to multiply but current global transition duration
        let rotationDelay = distanceFromTop * 600

        // set new current position, current rotation properties and inline style for clicked 
        item.currentPosition = 0
        item.currentRotation = newRotation
        item.style.transform = `rotate(-${newRotation}deg) translate(${this.iconSliderItems.clientWidth / 2}px) rotate(${newRotation}deg)`

        // add css variable to container to assist with rotation transition duration - (slows down the further away it is so to not spin too fast)
        this.iconSliderItems.style.setProperty('--js-transition-delay', `${rotationDelay}ms`)

        // set target's slide to active
        this.setActiveSlide(itemTargetSlide, rotationDelay)

        // update all sibling positions
        siblings.each((key, element) => {
            // get current position / rotation
            let siblingCurrentPosition = element.currentPosition,
                siblingCurrentRotation = element.currentRotation;

            // calculate new position / rotation
            let siblingNewRotation = Math.abs(siblingCurrentRotation + (distanceFromTop * this.rotationDeg)),
                siblingNewPosition = siblingCurrentPosition + (distanceFromTop);

            // catch to prevent numbers from scaling past the total (maximum) number of items
            if(siblingNewPosition > this.numOfItems) {
                siblingNewPosition = Math.abs(siblingNewPosition % this.numOfItems)
            }

            // set new current position, current rotation properties and inline style for sibling element
            element.currentRotation = siblingNewRotation
            element.currentPosition = siblingNewPosition
            element.style.transform = `rotate(-${siblingNewRotation}deg) translate(${this.iconSliderItems.clientWidth / 2}px) rotate(${siblingNewRotation}deg)`
        })
    }
};