import $ from 'jquery'

export default class ContentPostFilter {
    constructor(element, filterTitleClass, filterOptionsClass, filterOptionClass) {
        this.element = $(element)
        this.elementClassList = this.element.attr('class')
        this.filterTitle = this.element.find('option').eq(0).text()
        this.filterTitleClass = filterTitleClass || ''
        this.filterOptionsClass = filterOptionsClass || ''
        this.filterOptionClass = filterOptionClass || ''
        
        this.constructFilter()
        this.events()
    }
    
    constructFilter() {
        const filterOptions = this.element.contents().filter('option')
        let selectedFound = false

        let updatedFilters = $('<ul />', {
            class: this.filterOptionsClass
        })

        // console.log(`*--HIT--*  ¯\\_(ツ)_/¯  *--HIT--*\n['ContentPostFilter.js']\nLine: 24`)
        
        // * loop through found options and create new li's with contents
        filterOptions.each((key, element)=> {
            // * update text of first item to read 'All'
            if(key === 0) {
                $(element).text('All')
            }

            // * constuct filter-option
            let filterOption = $('<li />', {
                text: $(element).text(),
                value: $(element).val(),
                class: this.filterOptionClass,
            })

            // * if current item is selected set selected attribute and update global var
            if($(element).attr('selected')) {
                filterOption.attr('selected', true)
                selectedFound = true;
            }
            
            filterOption.appendTo(updatedFilters)
        })

        // * if no selected states are set default to 'all' option
        if(!selectedFound) {
            updatedFilters.children().eq(0).attr('selected', true)
        }

        // * construct and add new styled select to element
        this.element.after(() => {
            return $(`<div class="${this.elementClassList} js-select-toggle" />`)
                    .append(`<h2 class="${this.filterTitleClass} js-toggle-activator">${this.filterTitle}</h2>`)
                    .append(updatedFilters)
        });

        // * trawl DOM for newly added element and hide it in prep for show animation to run
        $(document).find('.c-content-posts__filter-options').hide()

        // store some dynamic elements
        this.filterContainer = this.element.next('.js-select-toggle')
    }

    events() {
        this.filterContainer.on('click', '.' + this.filterOptionClass, (event) => {
            event.stopPropagation()
            let target = $(event.target)

            target.attr('selected')
            this.element.val(target.attr('value'))
            target.parents('form').trigger('submit')
        })

        this.filterContainer.on('click', '.js-toggle-activator', (event) => {
            $(event.target).parent().toggleClass('is-active')

            $(event.target).siblings().toggleClass('is-active').stop().slideToggle({
                duration: 300,
                start: function() {
                    $(this).css('display','flex');
                },
            })
        })
    }
};
