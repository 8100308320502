import ScrollDirection from './modules/ScrollDirection'
import PrimaryNavigation from './modules/PrimaryNavigation'
import FooterNavigation from './modules/FooterNavigation'
import ContentPostFilter from './modules/ContentPostFilter'
import SliderMarquee from './modules/SliderMarquee'
import MediaLede from './modules/MediaLede'
import RotatingIconSliderSwiper from './modules/RotatingIconSliderSwiper'
import OffcanvasCheckState from './modules/OffcanvasCheckState'
import AnimatedMapPath from './modules/AnimatedMapPath'

new ScrollDirection('body', 25)
new OffcanvasCheckState()
new PrimaryNavigation()
new FooterNavigation()

$('.c-dynamic-svg-wrapper').each(function () {
    new AnimatedMapPath(this, '.c-map-path')
})

$('.c-media--lede').each(function () {
    new MediaLede(this);
})

$('.c-grid-filter--media .c-grid-filter__filter .c-grid-filter__select').each(function () {
    new MediaGridFilter()
})

$('.c-content-posts .c-content-posts__filter-select').each(function () {
    new ContentPostFilter(this, 'c-content-posts__filter-title', 'c-content-posts__filter-options', 'c-content-posts__filter-option')
})
// $('.c-content-posts .c-content-posts__radio-group').each( function () {
//     new ContentPostFilter(this, 'c-content-posts__filter-title', 'c-content-posts__filter-options', 'c-content-posts__filter-option')
// })

$('.c-rotating-icon-slider').each(function () {
    new RotatingIconSliderSwiper(this)
})

$(function () {
    // * added here to await for DOM ready before trying to init slider overrides
    $('.c-slider--marquee').each(function () {
        new SliderMarquee(this);
    });

    // *=====*-- apply a delay class to the header / announcement-banner --*=====* \\
    $(document).on('click', '.c-menu-button', function () {
        /**
        * * catches transition cross-overs if scroll has been triggered
        * * only register click when closing nav
        **/
        if ($('body').hasClass('navigation--is-active')) return

        $('.c-page-header, .c-announcement-header-banner').addClass('js-delay-scroll-hide')

        setTimeout(() => { $('.c-page-header, .c-announcement-header-banner').removeClass('js-delay-scroll-hide') }, 600)
    })

    $(window)
        .on('resize', function () {
            // *=====*-- Calculate Header Banner Height --*=====* \\
            if ($('html').hasClass('announcement-header-banner-is-active') && $('.c-announcement-header-banner').length > 0) {
                let containerHeight = $('.c-announcement-header-banner').outerHeight()

                document.documentElement.style.setProperty('--announcementHeight', `${containerHeight}px`)
            }
        })
        // *=====*-- Calculate Window innerHeight for mobile to allow offcanvas to fill screen --*=====* \\
        .on('resize', function () {
            let mobile = $('#c-mobile-indicator').is(':visible') || null

            if (mobile) {
                document.documentElement.style.setProperty('--windowHeight', `${window.innerHeight}px`)
            }
        })
        .trigger('resize')

    // *=====*-- remove sibling's ".is-active" on accordion item click --*=====* \\
    $('.c-accordion').each(function () {
        let element = $(this)
        let toggle = element.find('.c-accordion__anchor');

        $.each(toggle, (i, anchor) => {
            $(anchor).on('click', (e) => {
                $(e.target).parent().siblings().removeClass('is-active').children('.c-accordion__content').slideUp(300)

                $(e.target).siblings().stop().slideToggle(300)
            })
        })
    })

    // *=====*-- Calculate Media Hero Content Height --*=====* \\
    $('.c-media--hero').each(function () {
        let element = this

        $(window).on('resize', function () {
            let contentHeight = $(element).find('.c-media__inner').outerHeight(true)

            element.style.setProperty('--js-media-hero-content-height', `${contentHeight}px`)
        }).trigger('resize')
    })

    // *=====*-- Post Grid Filter - Filter Toggle  --*=====* \\
    $('.c-grid-filter--posts').each(function () {
        let element = $(this),
            filterLabel = element.find('.c-grid-filter__filter__label'),
            options = element.find('.c-grid-filter__option')

        options.wrapAll('<div class="c-grid-filter__filter-container">')

        $(element.find(".c-grid-filter__filter-container")).slideUp()

        filterLabel.on('click', function (event) {
            $(event.target).toggleClass('is-active').next().stop().slideToggle(400)
        })
    })

    $('.c-content-posts__radio-group').each(function(_, element) {
        const slider = $(element);
        let isDown = false,
            startX,
            scrollLeft;
    
        slider.on('mousedown', function(e) {
            isDown = true;
            slider.addClass('active');
            startX = e.pageX - slider.offset().left;
            scrollLeft = slider.scrollLeft();
        });
    
        slider.on('mouseleave mouseup', function() {
            isDown = false;
            slider.removeClass('active');
        });
    
        slider.on('mousemove', function(e) {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - slider.offset().left;
            const walk = (x - startX) * 2; // scroll-fast
            slider.scrollLeft(scrollLeft - walk);
            console.log(walk);
        });
    });
    
})
