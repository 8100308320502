export default class MediaLede {
    constructor(element) {
        this.element = $(element)
        this.imgCount = this.element.find(`.c-media__image`).length || null

        // * forces a minimum of 3 to be present before allowing functions to run
        if(this.imgCount <= 2) return;

        this.addImageClass()
        this.events()
    }

    addImageClass() {
        if(this.imgCount >= 5) {
            this.imgCount = 5
        }
        
        this.element.addClass(`c-media--lede--image-stack--${this.imgCount}`)
    }

    events() {
        let resizeTimer;

        $(window).on({
            'resize': () => {
                clearTimeout(resizeTimer)

                resizeTimer = setTimeout(() => {
                    // * Mobile check
                    let mobile = $('#c-mobile-indicator').is(':visible') || null

                    this.setGridSizes(mobile)
                }, 200);
            }
        }).trigger('resize')
    }

    setGridSizes(isMobile) {
        // * Sets selector based on what viewport is detected
        let nthImage = isMobile ? 1 : 3;

        // * based on smallest image
        const firstRowImageHeight = this.element.find(`.c-media__image:nth-of-type(${nthImage})`).outerHeight(true)
        this.element[0].style.setProperty(`--js-media-lede-first-row-image-height`, `${firstRowImageHeight}px`)

        if (!this.imgCount >= 4) return

        const innerHeight = this.element.find(`.c-media__inner`).outerHeight(true)
        this.element[0].style.setProperty(`--js-media-lede-content-height`, `${innerHeight}px`)
    }
}
