import $ from 'jquery'

class OffcanvasCheckState {
    constructor() {
        this.elements = {
            body: $('body, html'),
            menuButton: $('.c-menu-button'),
            bookButton: $('.c-button--book'),
            menuActiveClass: 'navigation--is-active',
            bookActiveClass: 'booking--is-active',
            pageWrap: $('.s-page-wrap')
        }

        this.bindEventListeners()
    }

    bindEventListeners() {
        this.elements.menuButton.on('click', (e) => this.menuCheckState(e))
        this.elements.bookButton.on('click', (e) => this.bookCheckState(e))
        this.elements.pageWrap.on('click', (e) => this.pageWrapClose(e))
    }

    menuCheckState(e) {
        e.preventDefault()

        if(this.elements.body.hasClass(this.elements.bookActiveClass))
            return this.elements.body.removeClass(this.elements.bookActiveClass)

        if(this.elements.body.hasClass(this.elements.menuActiveClass))
            return this.elements.body.removeClass(this.elements.menuActiveClass)

        this.elements.body.addClass(this.elements.menuActiveClass)
    }

    pageWrapClose() {
        if(this.elements.body.hasClass(this.elements.bookActiveClass) || this.elements.body.hasClass(this.elements.menuActiveClass)) {
            this.elements.body.removeClass(`${this.elements.bookActiveClass} ${this.elements.menuActiveClass}`)
        }

    }

    bookCheckState(e) {
        e.preventDefault()
        this.elements.body.removeClass(this.elements.menuActiveClass)
    }
}

export default OffcanvasCheckState