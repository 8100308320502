export default class FooterNavigation {
    constructor() {
        this.states = {
            active: 'is-active',
            mobileDisplay: $('#c-mobile-indicator'),
            // * Sets initial value and is overriden with previously calculated states
            originalDisplay: $('#c-mobile-indicator').is(':visible')
        }

        this.classes = {
            navigation: 'c-nav-footer',
            menu: 'menu',
            subMenu: 'sub-menu',
            menuItemsHasChildren:'menu__item--has-children',
        }

        this.elements = {
            menuItemsHasChildren: $(`.${this.classes.navigation} .${this.classes.menu} .${this.classes.menuItemsHasChildren}`),
            subMenuActivator: $(`.${this.classes.navigation} .${this.classes.menu} .${this.classes.menuItemsHasChildren} > a`),
            subMenus: $(`.${this.classes.navigation} .${this.classes.menu} .${this.classes.menuItemsHasChildren} .${this.classes.subMenu}`),
        }
        
        this.bindEventListeners()
    }

    bindEventListeners() {
        // * Will allow for resets to be calculated on resize, checks will not initially run inner functions due to initial match
        $(window).on('resize load', () => {
            // * Runs on 'mobile'
            if(this.states.mobileDisplay.is(':visible') && this.states.mobileDisplay.is(':visible') != this.states.originalDisplay) {
                this.reset(true)                

                // * updates original to current display value (immediately after finishing resize)
                return this.states.originalDisplay = this.states.mobileDisplay.is(':visible')
            }
            
            // * Runs on 'desktop'
            if(!this.states.mobileDisplay.is(':visible') && this.states.mobileDisplay.is(':visible') != this.states.originalDisplay) {
                this.reset(false)
                
                // * updates original to current display value (immediately after finishing resize)
                return this.states.originalDisplay = this.states.mobileDisplay.is(':visible')
            }
        })
                
        this.elements.subMenuActivator.on('click', (e) => {
            this.toggleSubNavs(e)
        })
    }

    toggleSubNavs(e) {
        e.preventDefault()

        // * Only run for mobile
        if(!this.states.mobileDisplay.is(':visible')) return
        
        // * Add active class to target and sibling sub-menu, and show/hide sub-menu on click
        $(e.target).toggleClass(this.states.active).siblings(`.${this.classes.subMenu}`).toggle(300).toggleClass(this.states.active)

        // * Remove parent's > sibling's > children's active states to prevent multiple tabs being opened
        $(e.target).parent().siblings().children().removeClass(this.states.active).siblings(`.${this.classes.subMenu}`).hide(300)
    }

    reset($display) {
        // * Removes all active classes
        this.elements.menuItemsHasChildren.children().removeClass(`${this.states.active}`)

        // * Will dynamically show/hide navs based on returned arguments (i.e. true == 'mobile reset' => hide())
        if($display) return this.elements.subMenus.hide()
        if(!$display) return this.elements.subMenus.show()   
    }
};