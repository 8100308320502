export default class PrimaryNavigation {
    constructor(element = '.c-nav-primary') {
        // * Where active classes for .navigation--is-active are set
        this.navActivatorTargets = $('html, body')

        // * Reset triggers
        this.menuButton = $('.c-menu-button')
        this.bookButton = $('.c-page-header__action')
        this.pageWrap = $('.s-page-wrap')

        // * Navigation elements
        this.elements = {
            base: $(element),
            menu: $(`${element} .menu`),
            subMenu: '.sub-menu',
            allMenuItemsWithChildrenTriggers: $(`${element} .menu__item--has-children > a`),
            menuItemsWithChildrenTrigger: $(`${element} .menu > .menu__item--has-children > a`),
            subMenuItemsWithChildrenTrigger: $(`${element} .sub-menu > .menu__item--has-children > a`),
        }

        // * Classes
        this.classes = {
            active: 'is-active',
            hidden: 'hidden',
            subMenuActive: 'sub-menu-is-active',
            menuActiveClass: 'navigation--is-active',
        }

        this.bindEventListeners()
    }

    bindEventListeners() {
        this.elements.menuItemsWithChildrenTrigger.on('click', (event) => this.revealSubMenus(event))
        this.elements.subMenuItemsWithChildrenTrigger.on('click', (event) => this.revealSubMenus(event, true))
        
        // * Menu restState triggers
        this.menuButton.on('click', () => this.resetState(false))
        this.bookButton.on('click', () => this.resetState())
        this.pageWrap.on('click', () => this.resetState())
    }

    revealSubMenus(event, nestedSubMenu = false) {
        event.preventDefault()

        let target = $(event.target)
        let hiddenClassPassThrough = this.classes.hidden
        
        target.toggleClass(this.classes.active)
        target.parent().toggleClass(this.classes.active)

        // * toggles hidden class to all other top-level nav items when top-level item is clicked
        if(!nestedSubMenu) {
            this.elements.menu.toggleClass(this.classes.subMenuActive)

            if(this.elements.menu.hasClass(this.classes.subMenuActive)) {
                target.parent().siblings().delay(300).queue( function(next) {
                    $(this).addClass(hiddenClassPassThrough)
                    next();
                })
            } else {
                target.parent().siblings().removeClass(hiddenClassPassThrough)
            }
        }

        if(nestedSubMenu) {
            target.closest(this.elements.subMenu).parent().toggleClass(this.classes.subMenuActive)
        }

        this.transformNavigation(event)
    }

    transformNavigation(event) {
        event.stopPropagation()

        const target = $(event.target).parent()
        const top = target.position().top
        var offset = ''
        
        // * animates activated sub-menu item to the top of the container
        if(target.hasClass(this.classes.active)) {
            target.data("originalTop", top)
            target.css({'top': top}).animate({'top': 0}, 300)
            target.css({'top': top, 'position': 'absolute'}).animate({'top': 0}, 300)

            target.closest(this.elements.menu).animate({scrollTop: 0})
            target.closest(this.elements.subMenu).parent().css({'background-image': 'none'})
        }

        /*
            * if absolute will naturally animate back to original position - but also used to reset top position 
            * (this prevents issues with it snapping after initial animation)
        */
        else {
            offset = target.data("originalTop")
            target.css({'top': 0}).animate({'top': offset + 'px'}, 200)
            
            // * this keeps the element as absolute for a delayed period of time (to be lined up with CSS animations)
            target.delay(150).queue(function (next) {
                $(this).css({'position': 'static'})
                $(this).closest('.sub-menu').parent().css({'background-image': ''})
                next();
            })
        }
    }

    resetState(menuActiveClass = true) {
        if(!this.navActivatorTargets.hasClass(this.classes.menuActiveClass)) return

        if(menuActiveClass) {
            this.navActivatorTargets.removeClass(this.classes.menuActiveClass)
        }

        this.elements.allMenuItemsWithChildrenTriggers.removeClass(`${this.classes.active} ${this.classes.subMenuActive}`)
        this.elements.allMenuItemsWithChildrenTriggers.parent().removeClass(`${this.classes.active} ${this.classes.subMenuActive}`)

        setTimeout(() => {
            this.elements.menu.removeClass(this.classes.subMenuActive)
            this.elements.allMenuItemsWithChildrenTriggers.removeClass(this.classes.active, this.classes.subMenuActive)
            this.elements.allMenuItemsWithChildrenTriggers.parent().each( (index, element) => { $(element).removeAttr('style').removeClass(this.classes.hidden) })
        }, 500)
    }
}
